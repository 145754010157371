import axios from 'axios';
import Cookies from 'universal-cookie';

const login = async (username, password) => {
  // console.log("Login " + username + " " + password);
  let result;
  try {
    result = await axios.post(window._env_.API_URL + '/login', { username, password });
  } catch (error) {
    if (error.response) {
      console.log('error response')
      console.log(error.response);
      return {
        status: error.response.status,
        data: error.response.data,
      }
      // Request made but the server responded with an error
    } else if (error.request) {
      console.log(error.request);
      return {
        status: 500,
        data: {
          error: "Tidak ada respon dari server",
        },
      }
      // Request made but no response is received from the server.
    } else {
      // console.log('apa nih');
      // Error occured while setting up the request
    }
  }
  // console.log(result);
  return result;
}

const logout = () => {
  // console.log("Logout");
  return true;
}

const ping = async () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let result;
  try {
    result = await axios.get(window._env_.API_URL + '/users/me', { headers: { 'Authorization': `Bearer ${token}`} } );
  } catch (error) {
    result = null;
    return null;
  }
  // console.log(result?.data);
  return result?.data;
}

export {
  login,
  logout,
  ping,
}