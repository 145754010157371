import axios from 'axios';
import Cookies from 'universal-cookie';

const createProduct = async (params) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  // console.log(params);
  let result;
  try {
    result = await axios.post(window._env_.API_URL + '/products', params, { headers: { 'Authorization': `Bearer ${token}`} });
  } catch (error) {
    if (error.response) {
      console.log('error response')
      console.log(error.response);
      return {
        status: error.response.status,
        data: error.response.data,
      }
      // Request made but the server responded with an error
    } else if (error.request) {
      console.log(error.request);
      return {
        status: 500,
        data: {
          error: "Tidak ada respon dari server",
        },
      }
      // Request made but no response is received from the server.
    } else {
      // console.log('apa nih');
      // Error occured while setting up the request
    }
  }
}

const updateStock = async (params) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  // console.log(params);
  let result;
  try {
    result = await axios.post(window._env_.API_URL + '/products/stock', params, { headers: { 'Authorization': `Bearer ${token}`} });
  } catch (error) {
    if (error.response) {
      console.log('error response')
      console.log(error.response);
      return {
        status: error.response.status,
        data: error.response.data,
      }
      // Request made but the server responded with an error
    } else if (error.request) {
      console.log(error.request);
      return {
        status: 500,
        data: {
          error: "Tidak ada respon dari server",
        },
      }
      // Request made but no response is received from the server.
    } else {
      // console.log('apa nih');
      // Error occured while setting up the request
    }
  }
}

const getProductOptions = async () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  // console.log("getProductOptions");
  const result = await axios.get(window._env_.API_URL + '/products/option', { headers: { 'Authorization': `Bearer ${token}`} } );
  // console.log(result.data);
  return result.data;
}

const getProductStock = async () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  // console.log("getProductStock");
  const result = await axios.get(window._env_.API_URL + '/products/stock', { headers: { 'Authorization': `Bearer ${token}`} } );
  // console.log(result.data);
  return result.data;
}

export {
  createProduct,
  updateStock,
  getProductOptions,
  getProductStock,
};