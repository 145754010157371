
import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faUser, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Image, Container, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import { login } from "../api/auth";
import { useMyStore } from "../util/store";
import BloomsBg from "../assets/img/blooms-bg.png";


export default () => {
  const [error, setError] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const initialValues = {
    "username": "",
    "password": "",
  };
  // const [formData, setFormData] = useState(defaultFormData);
  const handleLogin = async (value) => {
    // value.preventDefault();
    const { username, password } = value;
    setIsSubmitDisabled(true);
    setError(undefined);
    const result = await login(username, password);
    // console.log(result);
    if (result.status != 200) {
      // console.log(result.data);
      setError(result.data.error);
    } else {
      const { user, token } = result.data;
      // console.log(token);
      // console.log(user);
      setError(undefined);
      setCookie('token', token);
      setUser(user);
      history.push('/');
    }
    setIsSubmitDisabled(false);
  };
  // const handleChange = (e) => {
  //   const key = e.target.name;
  //   const value = e.target.value;
  //   setFormData({...formData, [key]: value});
  // }
  const [cookies, setCookie] = useCookies(['token']);
  const user = useMyStore((state) => state.user);
  const isLogin = cookies.token && user;
  const setUser = useMyStore((state) => state.setUser);
  const history = useHistory();
  useEffect(() => {
    if (isLogin) {
      history.push('/');
    }
  }, []);
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center"> */}
            {/* <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Kembali ke halaman utama
            </Card.Link> */}
            {/* Blooms Dashboard */}
          {/* </p> */}
          {/* <div className="w-200 h-100">
            <Image src={BloomsBg} fluid/>
          </div> */}
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image src={BloomsBg} fluid/>
                  {/* <h3 className="mb-0">LOGIN</h3> */}
                </div>
                <Formik initialValues={initialValues} onSubmit={handleLogin}>
                  {({
                    handleChange,
                    handleSubmit,
                  }) => (
                    <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Username</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                        <Form.Control autoFocus required type="text" placeholder="Masukkan username" name="username" onChange={handleChange}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control required type="password" placeholder="Masukkan password" name="password" onChange={handleChange}/>
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        {/* <Form.Check type="checkbox">
                          <FormCheck.Input id="defaultCheck5" className="me-2" />
                          <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                        </Form.Check> */}
                        {/* <Card.Link className="small text-end">Lost password?</Card.Link> */}
                      </div>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100" disabled={isSubmitDisabled}>
                      {!isSubmitDisabled && `Login`}
                      {isSubmitDisabled && <Spinner animation="border" />}
                    </Button>
                    {
                    error && <span className="fw-normal text-danger text-center text-md-center">
                      {error}
                    </span>
                    }
                  </Form>
                  )}
                </Formik>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
          {/* <Row className="justify-content-center">
            <Col xs={12} lg={6} className="mb-4 mb-lg-0">
              <p className="mb-0 text-center text-xl-left">
                Copyright © 2022-{`${2023} `}
                <Card.Link href="https://awang.id" target="_blank" className="text-blue text-decoration-none fw-normal">
                  awang.id
                </Card.Link>
              </p>
            </Col>
          </Row> */}
        </Container>
      </section>
    </main>
  );
};
