import axios from 'axios';
import Cookies from 'universal-cookie';

const getSalesByPeriod = async (month, year) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  // console.log("getSalesByPeriod");
  const result = await axios.get(window._env_.API_URL + `/sales?month=${month}&year=${year}`, { headers: { 'Authorization': `Bearer ${token}`} } );
  // console.log(result.data);
  return result.data.map((t, index) => {
    return {
      id: index + 1,
      product: t.itemName,
      tanggal: t.saleDate,
      harga: t.price,
      jumlah: t.quantity,
      discount: t.discount,
      total: t.totalPrice,
      buyer: t.buyer,
      branch: t.branch,
      via: t.via,
    }
  });
}

const addSale = async (sale) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  // const {sale_date, product_id, product_type, price, quantity, notes } = sale
  // console.log(sale);
  let result;
  try {
    result = await axios.post(window._env_.API_URL + '/sales', sale, { headers: { 'Authorization': `Bearer ${token}`} });
  } catch (error) {
    if (error.response) {
      console.log('error response')
      console.log(error.response);
      return {
        status: error.response.status,
        data: error.response.data,
      }
      // Request made but the server responded with an error
    } else if (error.request) {
      console.log(error.request);
      return {
        status: 500,
        data: {
          error: "Tidak ada respon dari server",
        },
      }
      // Request made but no response is received from the server.
    } else {
      // console.log('apa nih');
      // Error occured while setting up the request
    }
  }
  // let a = transactions[0];
  // transactions.push(a);
}

export {
  addSale,
  getSalesByPeriod,
};