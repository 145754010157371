import React, { useCallback, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal } from '@themesberg/react-bootstrap';

import { getProductOptions, getProductStock } from "../api/products";
import { Formik, useFormikContext } from "formik";
// import { useStateWithCallback } from "../util/useStateWithCallback";

export const UpdateStockModal = (props) => {
  const { onHide, handleSaveData } = props;
  const [products, setProducts] = useState({});
  const [currentQuantity, setCurrentQuantity] = useState(0);
  useEffect(() => {
    const fetchProductStock = async () => {
      const productOptions = await getProductStock();
      setProducts(productOptions);
      setCurrentQuantity(productOptions?.[0]?.currentQuantity)
      // return productOptions;
    }
    fetchProductStock();
    // const productOptions = fetchProductOptions();
    // console.log('use effect modal');
    // console.log(products);
    // setProducts(fetchProductOptions());
    // console.log(products);
  }, []);

  const initialValues = {
    product_id: products?.[0]?.id,
    product_current_quantity: products?.[0]?.currentQuantity,
  }

  return (
    <Modal as={Modal.Dialog} show centered onHide={onHide}>
    <Modal.Header>
      <Modal.Title className="h6">Sesuaikan Stok</Modal.Title>
      <Button variant="close" aria-label="Close" onClick={onHide} />
    </Modal.Header>
    <Modal.Body>
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSaveData}>
        {({handleChange, handleSubmit, setFieldValue, values}) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Product</Form.Label>
              <Form.Control as="select" name="product_id"
                onChange={e => {
                    const item = e.target;
                    const id = item.options[item.selectedIndex].dataset.id;
                    const current = item.options[item.selectedIndex].dataset.currentstock;
                    // console.log(e);
                    // console.log("value " + item.value);
                    // console.log("index " + item.selectedIndex);
                    // console.log(id);
                    // console.log(current);
                    setFieldValue('product_id', id);
                    setFieldValue('product_current_quantity', current);
                    setCurrentQuantity(current);
                    console.log(values);
                    // handleChange(e);
                  }
                  }>
                {products.length > 0 && products?.map((item, index) => <option value={index} data-id={item.id} data-currentstock={item.currentQuantity}>{item.productCode}-{item.name}-{item.color}-{item.size}</option>)}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Stok Di Sistem</Form.Label>
              <Form.Control type="number" name="product_current_quantity" value={currentQuantity} disabled/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Stok Aktual</Form.Label>
              <Form.Control type="number" name="new_quantity" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Keterangan Perubahan</Form.Label>
              <Form.Control type="text" name="notes" onChange={handleChange}/>
            </Form.Group>
            <Button variant="secondary" type="submit">
              Simpan
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={onHide}>
              Batal
            </Button>
          </Form>
      )}
      </Formik>
      </Modal.Body>
    </Modal>
  );
}
