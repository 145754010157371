import React, { useCallback, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal } from '@themesberg/react-bootstrap';

import { getProductOptions } from "../api/products";
import { Formik, useFormikContext } from "formik";
// import { useStateWithCallback } from "../util/useStateWithCallback";

export const AddTransactionModal = (props) => {
  const { onHide, handleSaveData } = props;
  const [products, setProducts] = useState({});
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [saleDate, setSaleDate] = useState(moment());
  useEffect(() => {
    const fetchProductOptions = async () => {
      const productOptions = await getProductOptions();
      // console.log(productOptions);
      setProducts(productOptions);
      setCurrentQuantity(productOptions?.[0]?.available);
      setPrice(productOptions?.[0]?.price);
      // return productOptions;
    }
    fetchProductOptions();
    // const productOptions = fetchProductOptions();
    // console.log('use effect modal');
    // console.log(products);
    // setProducts(fetchProductOptions());
    // console.log(products);
  }, []);

  const initialValues = {
    sale_date: saleDate,
    product_id: products?.[0]?.id,
    current_quantity: products?.[0]?.available,
    price: products?.[0]?.price,
  }

  return (
    <Modal as={Modal.Dialog} show centered onHide={onHide}>
    <Modal.Header>
      <Modal.Title className="h6">Tambah Data</Modal.Title>
      <Button variant="close" aria-label="Close" onClick={onHide} />
    </Modal.Header>
    <Modal.Body>
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSaveData}>
        {({handleChange, handleSubmit, setFieldValue, values}) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group classname="mb-3">
              <Form.Label>Tanggal</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                onChange={(date) => setFieldValue('sale_date', date)}
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    <Form.Control
                      required
                      name="sale_date"
                      type="text"
                      value={moment(values.sale_date).format("MM/DD/YYYY")}
                      placeholder="mm/dd/yyyy"
                      onFocus={openCalendar}
                      onChange={handleChange} />
                  </InputGroup>
                )} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Barang</Form.Label>
              <Form.Control as="select" name="product_id"
                onChange={(e) => {
                    const item = e.target;
                    const id = item.options[item.selectedIndex].dataset.id;
                    const currentQuantity_ = item.options[item.selectedIndex].dataset.currentstock;
                    const price_ = item.options[item.selectedIndex].dataset.price;
                    // console.log(e);
                    // console.log("value " + item.value);
                    // console.log("index " + item.selectedIndex);
                    // console.log(id);
                    // console.log(currentQuantity_);
                    // console.log(price_);
                    setFieldValue('product_id', id);
                    setFieldValue('current_quantity', currentQuantity_);
                    setFieldValue('price', price_);
                    setCurrentQuantity(currentQuantity_);
                    setPrice(price_);
                    // handleChange(e);
                    // console.log(values);
                  }
                  }>
                {products.length > 0 && products?.map((item, index) => <option value={index} data-id={item.id} data-currentstock={item.available} data-price={item.price}>{item.name}</option>)}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tersedia</Form.Label>
              <Form.Control type="number" name="current_quantity" value={currentQuantity} disabled/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Harga</Form.Label>
              <Form.Control type="number" name="price" value={price} disabled/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Diskon</Form.Label>
              <Form.Control type="number" name="discount" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Jumlah</Form.Label>
              <Form.Control type="number" name="quantity" onChange={handleChange}/>
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Alamat Tujuan</Form.Label>
              <Form.Control as="textarea" rows="3" name="alamat_tujuan" onChange={handleChange}/>
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label>Pembeli</Form.Label>
              <Form.Control type="text" name="buyer" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cabang</Form.Label>
              <Form.Control type="text" name="branch" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Via</Form.Label>
              <Form.Control type="text" name="via" onChange={handleChange}/>
            </Form.Group>
            <Button variant="secondary" type="submit">
              Simpan
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={onHide}>
              Batal
            </Button>
          </Form>
      )}
      </Formik>
      </Modal.Body>
    </Modal>
  );
}
