import React, { useCallback, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal } from '@themesberg/react-bootstrap';

import { getProductOptions } from "../api/products";
import { Formik, useFormikContext } from "formik";
// import { useStateWithCallback } from "../util/useStateWithCallback";

export const AddproductModal = (props) => {
  const { onHide, handleSaveData } = props;
  // const [products, setProducts] = useState({});
  // useEffect(() => {
  //   const fetchProductOptions = async () => {
  //     const productOptions = await getProductOptions();
  //     setProducts(productOptions);
  //     // return productOptions;
  //   }
  //   fetchProductOptions();
  //   // const productOptions = fetchProductOptions();
  //   // console.log('use effect modal');
  //   // console.log(products);
  //   // setProducts(fetchProductOptions());
  //   // console.log(products);
  // }, []);

  const initialValues = {
  }

  return (
    <Modal as={Modal.Dialog} show centered onHide={onHide}>
    <Modal.Header>
      <Modal.Title className="h6">Buat Produk</Modal.Title>
      <Button variant="close" aria-label="Close" onClick={onHide} />
    </Modal.Header>
    <Modal.Body>
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSaveData}>
        {({handleChange, handleSubmit, setFieldValue, values}) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Nama Produk</Form.Label>
              <Form.Control type="text" name="name" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Deskripsi</Form.Label>
              <Form.Control type="text" name="description" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Kode Barang</Form.Label>
              <Form.Control type="text" name="productCode" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Kode Keuntungan</Form.Label>
              <Form.Control type="text" name="internalCode" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Warna</Form.Label>
              <Form.Control type="text" name="color" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ukuran</Form.Label>
              <Form.Control type="text" name="size" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Harga Jual</Form.Label>
              <Form.Control type="number" name="price" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Stok</Form.Label>
              <Form.Control type="number" name="currentQuantity" onChange={handleChange}/>
            </Form.Group>
            <Button variant="secondary" type="submit">
              Simpan
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={onHide}>
              Batal
            </Button>
          </Form>
      )}
      </Formik>
      </Modal.Body>
    </Modal>
  );
}
