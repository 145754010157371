
import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faPlus, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
// import transactions from "../data/transactions";
import commands from "../data/commands";
import { getSalesByPeriod } from "../api/sales";
import { getProductStock } from "../api/products";

// Create our number formatter.
const idrFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon icon={bounceIcon} className={`${bounceTxtColor} me-3`} />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">See all</Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map(pv => <TableRow key={`page-visit-${pv.id}`} {...pv} />)}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const { id, source, sourceIcon, sourceIconColor, sourceType, category, rank, trafficShare, change } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">{id}</Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon icon={sourceIcon} className={`icon icon-xs text-${sourceIconColor} w-30`} />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar variant="primary" className="progress-lg mb-0" now={trafficShare} min={0} max={100} />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const { country, countryImage, overallRank, overallRankChange, travelRank, travelRankChange, widgetsRank, widgetsRankChange } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image src={countryImage} className="image-small rounded-circle me-2" />
            <div><span className="h6">{country}</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {overallRank ? overallRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">
          {travelRank ? travelRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">
          {widgetsRank ? widgetsRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map(r => <TableRow key={`ranking-${r.id}`} {...r} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = (props) => {
  // const totalTransactions = transactions.length;
  const { salesDate, refreshSalesList } = props;
  const { month, year } = salesDate;
  const [sales, setSales] = useState([]);

  const fetchSales = async (month, year) => {
    const result = await getSalesByPeriod(month, year);
    setSales(result);
  }

  useEffect(() => {
    // console.log("use effect nih");
    // console.log(month);
    // console.log(year);
    fetchSales(month, year);
    // let trx = getSalesByPeriod(month, year);
    // console.log(trx);
    // setSales(trx);
    // console.log(sales);
  }, [month, year, refreshSalesList]);

  const totalSales = useMemo(
    () => {
      let sum = 0;
      for (const item of sales) {
        sum += item.total;
      }
      return sum;
    },
    [sales],
  );
  const TableRow = (props) => {
    const { id, product, tanggal, jumlah, harga, discount, total, buyer, branch, via } = props;
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          {/* <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {id}
          </Card.Link> */}
          <span className="fw-normal">
            {id}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {tanggal}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {buyer}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {branch}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {product}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {jumlah}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {/* ${parseFloat(harga).toFixed(2)} */}
            {idrFormatter.format(harga)}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {/* ${parseFloat(harga).toFixed(2)} */}
            {idrFormatter.format(discount)}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {/* ${parseFloat(harga).toFixed(2)} */}
            {idrFormatter.format(total)}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {via}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Header>
        Total Penjualan: <b>{idrFormatter.format(totalSales)}</b>
      </Card.Header>
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">No</th>
              <th className="border-bottom">Tanggal</th>
              <th className="border-bottom">Pembeli</th>
              <th className="border-bottom">Cabang</th>
              <th className="border-bottom">Barang</th>
              <th className="border-bottom">Jumlah</th>
              <th className="border-bottom">Harga</th>
              <th className="border-bottom">Diskon</th>
              <th className="border-bottom">Total</th>
              <th className="border-bottom">Via</th>
            </tr>
          </thead>
          <tbody>
            {sales.length > 0 && sales.map(t => <TableRow key={`transaction-${t.id}`} {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          {/* <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav> */}
          {/* <small className="fw-bold">
            Showing <b>{sales.length}</b> out of <b>{sales.length}</b> entries
          </small> */}
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const ProductsTable = (props) => {
  const { refreshProductList } = props;
  // const totalTransactions = transactions.length;
  const [products, setProducts] = useState([]);

  const fetchProductStock = async () => {
    const result = await getProductStock();
    setProducts(result);
  }

  useEffect(() => {
    // console.log("use effect nih");
    fetchProductStock();
    // console.log(products);
  }, [refreshProductList]);

  const TableRow = (props) => {
    const { id, name, productCode, color, size, currentQuantity, price } = props;
    return (
      <tr>
        {/* <td>
          <span className="fw-normal">
            {id}
          </span>
        </td> */}
        <td>
          <span className="fw-normal">
            {productCode}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {color}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {size}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {currentQuantity}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {/* ${parseFloat(harga).toFixed(2)} */}
            {idrFormatter.format(price)}
          </span>
        </td>
        {/* {
          stocks.map(s => (
            <td>
              <span className="fw-normal">
                {s.quantity}
              </span>
            </td>
          ))
        } */}
      </tr>
    );
  };
  
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              {/* <th className="border-bottom">Id</th> */}
              <th className="border-bottom">Kode Barang</th>
              <th className="border-bottom">Nama</th>
              <th className="border-bottom">Warna</th>
              <th className="border-bottom">Ukuran</th>
              <th className="border-bottom">Stok</th>
              <th className="border-bottom">Harga</th>
              {/* {products.length > 0 && products[0].stocks?.map(t => <th className="border-bottom">{t.warehouseName}</th>)} */}
            </tr>
          </thead>
          <tbody>
            {products.length > 0 && products?.map(t => <TableRow key={`product-${t.id}`} {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          {/* <small className="fw-bold">
            Showing <b>{products.length}</b> out of <b>{products.length}</b> entries
          </small> */}
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const PackagesTable = (props) => {
  const { packageDetail, items } = props;
  const { packageName, packageHpp, packageAvailable } = packageDetail

  // useEffect(() => {
  //   console.log("use effect nih");
  //   fetchProductStock();
  //   console.log(products);
  // }, [refreshProductList]);

  const TableRow = (props) => {
    const { id, name, quantityNeeded, currentQuantity, hpp } = props;
    return (
      <tr>
        {/* <td>
          <span className="fw-normal">
            {id}
          </span>
        </td> */}
        <td>
          <span className="fw-normal">
            {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {quantityNeeded} / {currentQuantity}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {idrFormatter.format(hpp)}
          </span>
        </td>
      </tr>
    );
  };
  
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm mx-4 my-4">
      <Card.Header>
        <b>{packageName}</b><br />
        HPP: <b>{idrFormatter.format(packageHpp)}</b><br />
        Tersedia: <b>{packageAvailable}</b>
      </Card.Header>
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              {/* <th className="border-bottom">ID Produk</th> */}
              <th className="border-bottom">Nama Produk</th>
              <th className="border-bottom">Kebutuhan / Stok Tersedia</th>
              <th className="border-bottom">HPP</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 && items?.map(t => <TableRow key={`product-${t.id}`} {...t} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: '5%' }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: '5%' }}>
          <ul className="ps-0">
            {usage.map(u => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: '50%' }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: '40%' }}>
          <pre><Card.Link href={link} target="_blank">Read More <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" /></Card.Link></pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table responsive className="table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: '5%' }}>Name</th>
              <th className="border-0" style={{ width: '5%' }}>Usage</th>
              <th className="border-0" style={{ width: '50%' }}>Description</th>
              <th className="border-0" style={{ width: '40%' }}>Extra</th>
            </tr>
          </thead>
          <tbody>
            {commands.map(c => <TableRow key={`command-${c.id}`} {...c} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};