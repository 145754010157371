import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useMyStore = create(
  persist(
    (set) => ({
      // userId: undefined,
      // setUserId: (value) => set({userId: value}),
      // token: undefined,
      // setToken: (value) => set({token: value}),
      user: undefined,
      setUser: (value) => set({user: { id: value?.id, name: value?.name, username: value?.username, }})
    }),
    {name: 'blooms'}
  )
);