
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Image } from '@themesberg/react-bootstrap';

import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import BloomsBg from "../../assets/img/blooms-bg.png";

export default () => {
  return (
    <>
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
        <div className="text-center text-md-center mb-4 mt-md-0">
          <Image src={BloomsBg} fluid/>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"> */}
      {/* <h1>Blooms Dashboard</h1> */}
      {/* <Image src={BloomsBg} fluid/> */}
      {/* </div> */}
    </>
  );
};
