import React, { useState } from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal } from '@themesberg/react-bootstrap';

import { TransactionsTable } from "../components/Tables";
import { addSale } from "../api/sales";
// import { getProductOptions } from "../api/products";
import { AddTransactionModal } from "./AddTransactionModal";

export default () => {
  const [showAddDataModal, setShowAddDataModal] = useState(false);
  const [salesDate, setSalesDate] = useState({month: moment().month(), year: moment().year()});
  const [refreshSalesList, setRefreshSalesList] = useState(false);
  const handleCloseAddDataModal = () => setShowAddDataModal(false);
  const handleAddDataClick = () => {
    setShowAddDataModal(true);
  }
  const handleAddSale = async (sale) => {
    await addSale(sale);
    setShowAddDataModal(false);
    setRefreshSalesList(!refreshSalesList);
  }
  return (
    <>
      {showAddDataModal && <AddTransactionModal onHide={handleCloseAddDataModal} handleSaveData={handleAddSale} />}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item href="/transactions"><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Keuangan Harian</Breadcrumb.Item>
          </Breadcrumb> */}
          <h4>Penjualan</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm" onClick={foo}>Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
        </div> */}
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
            <Button variant="outline-primary" size="sm" onClick={handleAddDataClick}>
              <FontAwesomeIcon icon={faPlus} className="me-1"/>Tambah Data
            </Button>
          </Col>
          <Col xs={8} md={6} lg={3} xl={4}>
            <b>Pilih Bulan</b>
            <Datetime
                timeFormat={false}
                closeOnSelect={true}
                dateFormat="YYYY-MM"
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    <Form.Control
                      required
                      name="transaction_date"
                      type="text"
                      // value={moment().format("MM/DD/YYYY")}
                      value={moment({year: salesDate.year, month: salesDate.month}).format("MMM YYYY")}
                      placeholder="Pilih bulan"
                      onFocus={openCalendar}
                      onChange={() => {console.log(props)}} />
                  </InputGroup>
                )}
                onChange={(v) => {
                  // console.log(v.month() + " " + v.year());
                  setSalesDate({month: v.month(), year: v.year()});
                  // setMonth(v.month());
                  // setYear(v.year());
                }}
                />
              </Col>
          {/* <Col xs={4} md={2} xl={1} className="ps-md-0 text-end"> */}
            {/* <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          {/* </Col> */}
        </Row>
      </div>

      <TransactionsTable salesDate={salesDate} refreshSalesList={refreshSalesList}/>
    </>
  );
};
